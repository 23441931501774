
import React from 'react';
import { EnumBureausShorts, Outcome, AutoSelectTypesForLetters, DisputeReason } from '../models/enums';
import { ICheckboxList, IValueText } from '../models/interfaces/shared';
import { BureauLogoComponent } from './components/bureau-logo';

export const Constants = {
    authSessionKey: 'auth',
    siteLogoPath: '/images/logo/{0}/SiteLogo.jpeg',
    siteLogoMiniPath: '/images/logo/{0}/SiteLogoMini.jpeg',
    officeLogoPath: '/images/logo/{0}/offices/{1}/OfficeLogo.jpeg',
    officeLogoMiniPath: '/images/logo/{0}/offices/{1}/OfficeLogoMini.jpeg',
};
export const APIConstants = {
    auth: '/login',
    configuration: '/configuration',
    getStats: '/stats',
    getCustomers: '/customer',
    getCustomer: '/customer/{0}', //{customerid}
    getCustomerMinimaldetails: '/customer/{0}/minimaldetails', //{customerid}
    getCustomersAndLeads: '/CustomersLeads/{0}', // {customerSelected}
    saveFeeDetails: '/customer/updatefeeinfo',
    saveCreditMonitoring: '/customer/updatemonitoringdetail',
    getCreditMonitoring: '/customer/getmonitoringdetail/{0}/{1}', // {customerId}/{typeOfClient}
    saveGeneralNotes: '/customer/updategennotes',
    updateUserName: '/customer/updateusername',
    updatePassword: '/customer/updatepassword',
    updateLockOrUnlockCustomerUser: '/customer/lockorunlockuser',
    updateLockOrUnlockAgentUser: '/franchiseAgents/lockorunlockuser',
    updateLockOrUnlockReferralAgentUser: '/ReferralAgent/lockorunlockuser',
    checkS3Files: '/customer/GetS3JSONRecentFiles',
    getS3SingleFile: '/customer/gets3jsonfiledata',
    adminsettings: '/customer/adminsettings',
    submitRounds: '/customer/submitrounds',
    submitQuery: '/customer/submitquery/{0}',
    saveCreditScore: '/customer/savecreditscore',
    customerFranchAgents: '/customer/franchiseagent',
    customerReferAgents: '/customer/referralagent',
    updateCustomerAgent: '/customer/agent',
    disputeProgress: '/customer/getdisputeprogressstatistics/{0}', // {0} cid
    resendWelcomeEmail: '/customer/resendwelcomeemail/{0}', // {0} customerid
    submitToProcessing: '/customer/SubmitToProcessing/{0}', // {0} customerid
    submitToArrayReport: '/customer/arraysubscription/{0}', // {0} customerid
    creditmonitoringsubscription: '/customer/creditmonitoringsubscription/{0}', // {0} customerid
    getcurrentscore: '/customer/getcurrentscore/{0}', // {0} customerid
    getAccountsNumberByType: '/customer/getaccountsnumberbytype/{0}', // {0} customerId
    getAccountsNumber: '/customer/getaccountsnumber/{0}', // {0} customerId
    sendPORneeded: '/customer/sendporneeded/{0}', // {0} customerid
    removeSpouse: '/customer/removespouse/{0}/{1}', // {0} customerid {1} soupseid
    fastEditAccounts: '/customer/getaccounts',
    customerReferralAgents: '/customer/referralpartners/{0}', // {0} customerid {1} 
    addUpdateCustomerReferralAgents: '/customer/referralpartners/{0}/{1}/{2}', // {0} customerid {1} -agentid,{2} rolename
    deleteCustomerReferralAgents: '/customer/referralpartners/{0}/{1}', // {0} customerid {1} -agentid
    toDos: '/todo/{0}/{1}', // {0} cid, {1} enum ToDoTargetTypes
    toDoUpdate: '/todo/{0}', // {0} todoid
    getCustomersCount: '/dashboard/customer',
    getFranchiseAgentsCount: '/dashboard/franchise',
    getReferralAgentsCount: '/dashboard/referral',
    getCustomerLettersCount: '/dashboard/customerLetter',
    getRolesCount: '/dashboard/role',
    getFranchiseAgents: '/common/franchiseagents',
    getReferralAgents: '/common/referralagents',
    getStates: '/common/states',
    checkPartnerkey: '/common/checkpartnerkey/{0}', // {officeid}
    checkAPIActive: '/common/checkapiactive/{0}', // api name {MailChimp}
    searchCollection: '/common/search/{0}?q={1}&l={2}', // {0} enumb CollectionEntryTypes,{1} text,{2} maxrecords
    getAllBureaus: '/common/bureaus',
    getAllAccountTypes: '/common/accounttypelist',
    getAllCollections: '/collectionentry/getAllCollections/{0}/{1}', //type, textSearch
    getAllLetterTypes: '/common/disputeLetterTypes',
    getAllSystemTypes: '/collectionentry/systemTypes',
    getAccountsCounterByType: '/collectionentry/GetAccountsByTypes/{0}/{1}', //id, type
    updateCollectionAndType: '/collectionentry/updateSystemTypes/{0}/{1}/{2}', // id, name, type
    updateTypeInAccount: '/collectionentry/updateTypeInAccount/{0}/{1}/{2}', // oldId, newId typeOfType
    createCollection: '/collectionentry/createSystemTypes/{0}/{1}', //name, type
    getRoles: '/',
    getCustomerLetters: '/',
    getCreateLetterVersion: '/letter/getcreateletterversion/{0}', // {siteId}
    updateCreateLetterVersion: '/letter/updatecreateletterversion',
    getScoringRoundStatuses: '/scoring/statuses',
    checkSubscriberListsByEmail: '/mailchimp/checkSubscriberListsByEmail',
    getMailchimpLists: '/mailchimp/getMailchimpLists',
    checkSubscriberCampaignsByEmail: '/mailchimp/checkSubscriberCampaignsByEmail',
    setMailchimpList: '/mailchimp/setMailchimpList',
    getAPIIntegrations: '/mailchimp/getAPIIntegrations',
    setAPIActive: '/mailchimp/setAPIActive',
    updateAPIKey: '/mailchimp/updateAPIKey',
    updateRound: '/scoring/round',
    deleteRound: '/scoring/round/{0}',
    addNewRound: '/scoring/addNewRound/{0}', // cid
    saveClientNote: '/note',
    processingNote: '/internalnote',
    getCannedNotes: '/cannednote/{0}', // noteType {0}
    createCannedNote: '/cannednote?note={0}&noteType={1}',
    collectionAccount: '/collectionaccount',
    account: '/account?accountId={0}&customerId={1}',
    file: '/file',
    updateFile: '/file?fileId={0}&filetype={1}&isLead={2}',
    disputeletter: '/disputeletter',
    letter: '/letter',
    downloadFileLetter: '/file?filePath={0}', // {0} file path coming from BE,
    downloadLastReport: '/finandgetlastfilereport?customerId={0}', // {0} file path coming from BE,
    updateCollectorAddress: '/fastedit/updateaddress',
    addCollectorAddress: '/fastedit/addaddress',
    getCollectorAddressByData: '/fastedit/getaddressbydata/{0}/{1}/{2}/{3}/{4}', //{0}collectorid / {1}address1 / {2}city / {3}state / {4}zip
    deleteCollectorAddress: '/fastedit/deleteaddress',
    saveFEAccounts: '/fastedit/saveaccounts',
    saveFEAccountsMini: '/fastedit/saveminiaccount',
    updateYellowAccount: '/fastedit/removeupdated/{0}', // {0} ccid
    addNewAccount: '/fastedit/createnewaccount',
    getFELetterData: '/fastedit/getletterdata/{0}', // {0} cid
    getFEFullAccountDetails: '/fastedit/getaccountdetail/{0}',// {0} ccid
    saveFullAccountDetails: '/fastedit/fullsave',
    deleteBureauDetail: '/fastedit/deletebureau/{0}/{1}', // {0} cabid {1} ccid
    getPDFDisputeLetters: '/disputeletter/{0}', // {cid}
    getTempLetterQueue: '/letter/tempqueue/{0}', // {cid}
    letterSearch: '/letter/{0}?query={1}',
    getLetterTemplatesSuggestions: '/lettersuggestions/{0}/{1}', //{0}{searchType} {1}{round}
    createCredBCCLetter: '/letter/createcredccbcctempletter',
    createCRALetter: '/letter/createsingletempletter',
    createBureauLetter: '/letter/createtempletter',
    deleteTempLetter: '/letter/deletetempletterqueue',
    saveTempLetter: '/letter/savequeueletter',
    ccList: '/letter/CCList/{0}', // {0} cid
    createPDFLetter: '/letter/createpdfletters',
    restartRound: '/letter/startrestartround',
    setRounds: '/letter/setrounds',
    sendNoticeAccUpdate: '/letter/sendNoticeAccUpdate',
    regenerateNoticeAccountUpdate: '/letter/regeneratenoticeaccountupdate/{0}', // {0} cid
    getAuthRules: '/viewaccess/byrole',
    allAuthRules: '/viewaccess',
    useraccessDelete: '/ViewAccess/DeleteScreen/{0}', // screen name
    useraccessUpdate: '/ViewAccess/UpdateScreen',
    useraccessFieldDelete: '/ViewAccess/DeleteField/{0}', // field id
    useraccessFieldUpdate: '/ViewAccess/UpdateField', // 
    importSave: '/importer',
    reImportSave: '/reimporter',
    getS3JSONFileData: '/importer/getS3JSONFileData?fileName={0}', // filename
    freeTrialImporter: '/importer/getFreeTrialCredit',
    getLastReportImportedDate: '/importer/GetLastReportImportedDate/{0}', // customerId
    forgotPasswordLInk: '/userAccount/sendResetPasswordLink',
    verifyResetLink: '/userAccount/verifyResetPasswordLink',
    resetPassword: '/userAccount/resetPassword',
    updateLastLoginDate: '/userAccount/updateLastLoginDate',
    franchiseOffices: '/franchiseOffices',
    getFranchiseOfficeDetails: '/franchiseOffices/{0}', // {0} office id
    getFranchiseOfficeAgents: '/franchiseOffices/{0}/agents', // {0} office id
    getCurrentOfficeAgents: '/franchiseOffices/currentOfficeAgents',
    getFranchiseSingleAgentDetails: '/franchiseAgents/{0}', //{0} agentid
    updateFranchiseAgent: '/franchiseAgents',
    getFranchiseAgentRoles: '/franchiseAgents/roles',
    getFranchiseOfficeServices: '/franchiseOffices/{0}/services', // {0} office id
    getFranchiseOfficeOutsourcedServices: '/franchiseOffices/{0}/services', // {0} office id
    createFranchiseOfficeServices: '/franchiseOffices/services', // {0} office id
    deleteFranchiseOfficeServices: '/franchiseOffices/{0}/services/{1}', // {0} office id,{1} service id
    getFranchiseOfficePayments: '/franchiseOffices/{0}/payments', // {0} office id    
    deleteFranchiseOfficePayment: '/franchiseOffices/{0}/payments/{1}', // {0} office id,{1} paymentid    
    getLeads: '/lead/getall', // {0} --query
    getSingleLeads: '/lead/{0}', // leadid
    resendLeadWelcomeEmail: '/lead/resendwelcomeemail/{0}', // {0} leadid
    addLead: '/lead',
    convertToCustomer: '/lead/ConvertToCustomer/{0}/{1}', // {0} --leadid,{1}- notify agent?
    leadCreditMonitoring: '/lead/CreditMonitoring',
    leadAgentUpdate: '/lead/agent',
    removeLeadSpouse: '/lead/removespouse/{0}/{1}', // {0} leadid {1} soupseid
    getFranchiseAgentsList: '/franchiseAgents/getall', // {0} --query
    getFranchiseAgentNotes: '/franchiseAgents/notes/{0}', // {0} --membershipid of agent or note id to delete
    addFranchiseAgentNote: '/franchiseAgents/notes',
    getFranchiseAgentCustomers: '/franchiseAgents/{0}/customers', //0- agent id
    getFranchiseAgentReferrals: '/franchiseAgents/{0}/referralPartners', //0- agent id
    franchAgentWelcomeEmail: '/franchiseAgents/ResendWelcomeEmail/{0}',
    referralOffices: '/referralOffices',
    getReferralOfficeDetails: '/referralOffices/{0}', // {0} office id
    getReferralOfficeAgents: '/referralOffices/{0}/agents', // {0} office id
    getReferralOfficeAgentDetails: '/referralAgent/{0}', // {1} agentid
    reassignReferralOffices: '/referralOffices/reassign/{0}/{1}', // {0} current id,{1} asign to id
    updateReferralAgent: '/referralAgent',
    getReferralSingleAgentDetails: '/referralAgents/{0}',
    referralAgentChangePassword: '/referralAgent/ChangePassword',
    resendWelcomeAgentEmail: '/referralAgent/ResendWelcomeEmail/{0}',// agent id
    resendFollowUpAgentEmail: '/referralAgent/ResendFollowUpEmail/{0}',// agent id
    getReferralAgentsList: '/referralAgent/getall', // {0} --query
    getReferralAgentCustomers: '/referralAgent/{0}/customers/{1}', //0- agent id, 1- type 0/1
    getAllEmailTemplates: '/lettertemplate/getall',
    getAllEmailTemplatesSearch: '/lettertemplate/getallsearch/{0}',
    getAllSubFieldsForSection: '/lettertemplate/getSubSectionTypes/{0}', // accounts/names/inquires
    getFinalCollectionToken: '/lettertemplate/getFinalCollectionToken',
    emailTemplate: '/lettertemplate/{0}', /// letter id
    emailTemplateUpdate: '/lettertemplate',
    emailTemplateCustomerTokens: '/lettertemplate/getCustomersFieldTokens',
    emailTemplateFieldTokens: '/lettertemplate/getFieldsTokens',
    emailTemplateGenerateHTMLPreview: '/lettertemplate/GenerateHTMLPreview',
    getEmailTemplatesByOfficeId: '/lettertemplate/GetEmailTemplatesByOfficeId/{0}', // office id
    setEmailTemplateActive: '/lettertemplate/SetEmailTemplateActive/{0}/{1}/{2}', // ofc id, letter IdleDeadline, active/or not
    getEmailTemplatesById: '/lettertemplate/GetEmailTemplatesById/{0}',
    updateOfcEmailTemplate: '/lettertemplate/UpdateOfcEmailTemplate',
    getSavedSampleTemplates: '/lettertemplate/GetSavedSampleTemplates/{0}', // email type
    getOfficeLeadFormOptions: '/lettertemplate/GetOfficeLeadFormOptions/{0}', // ofc id
    updateOfficeLeadFormOptions: '/lettertemplate/UpdateOfficeLeadFormOptions/{0}', // ofc id
    createOfficeLeadFormOptions: '/lettertemplate/CreateOfficeLeadForm/{0}', // ofc id
    emailTemplateOptions: '/lettertemplate/emailTemplateOptions/{0}/{1}', // templateid and data type
    removeEmailTemplateOptions: '/lettertemplate/emailTemplateOptions/{0}', // id
    getPublicToken: '/GetAccessToken/{0}', // tenant name
    getSiteLogos: '/media/getSiteLogos',
    uploadLogo: '/media/upload',
    removeLogo: '/media/delete/office/{0}', // 0 ofc id
    allOutsourcedServices: '/service/getall',
    outsourceFranchiseService: '/service',
    collectionEntry: '/collectionEntry',
    getFranchiseMasterServices: '/service/getall',
    createFranchiseMasterServices: '/service',
    franchiseMasterServiceLevel: '/servicelevel',
    franchiseMasterServiceAdons: '/serviceAddOn',
    getFranchiseMasterServiceCategories: '/ServicePricing/{0}', // serviceid
    saveFranchiseMasterServiceCategories: '/ServicePricing',
    removeFranchiseServiceCategory: '/ServicePricing/category/{0}',
    removeFranchiseServiceCategoryLevel: '/ServicePricing/level/{0}',
    removeFranchiseServiceCategoryLevelOption: '/ServicePricing/addons/{0}',
    getFranchiseOfficeAvailableServices: '/FranchiseOfficeServicePricing/GetAllServicesAvailable/{0}',
    getCustomerAvailableServices: '/UserServicePricing/GetAllServicesAvailable/{0}/{1}', // {0} as FrancficeId, {1} as Cid/lid
    getFranchiseOfficeAvailableCategories: '/FranchiseOfficeServicePricing/Get/{0}/{1}', // {0} franchise office id, {1} master serviceid
    getFranchiseOfficeUserAvailableCategories: '/UserServicePricing/Get/masterserviceid/{0}/franchiseOfficeId/{1}/userId/{2}', // {1} franchise office id, {0} master serviceid, {2} user id
    saveCatogoryForFranchiseOffice: '/FranchiseOfficeServicePricing',    
    removeCategoryFromOffice: '/FranchiseOfficeServicePricing/ServicePricing/{0}', // servicePricingId
    removeAddOnFromOffice: '/FranchiseOfficeServicePricing/addons/{0}', // pricingAddOnId
    removeLevelFromOffice: '/FranchiseOfficeServicePricing/Level/{0}', // pricingLevelId
    getFranchiseOfficeAllPricings: '/FranchiseOfficeServicePricing/GetAll/{0}', // office id
    savePricingCatogoryForUser: '/UserServicePricing',
    removeServicePricingForUser: '/UserServicePricing/ServicePricing/{0}', // 0 - servicePricingId
    removeServiceAddOnForUser: '/UserServicePricing/addons/{0}',  // 0- userServicePricingAddOnId
    getCreditReportForUser: '/CreditReport/{0}/{1}', // user id and boolean for email send ?
    getCreditReportForUserFromIQHtmlReport: '/CreditIQReport/{0}/{1}', // user id and boolean for email send ?
    fetchReport: '/arrayreport/fetch/{0}/{1}', //userId // isLead
    getDateOfLastRequestFetchReport: '/arrayreport/getdateoflastrequest/{0}', //userId
    getLoginListHistory: '/userAccount/getLogins'
};
export const ClientRoutesConstants = {
    notFound: '/notFound',
    unauthorized: '/unauthorized',
    dashboard: '/dashboard',
    login: '/login',
    logout: '/logout',
    forgetPassword: '/forget-password',
    register: '/register',
    security: '/security',
    customers: '/customers',
    customersAdd: '/customers/add',
    customersView: '/customers/:cid',
    fastEditAccounts: '/fasteditacounts/:cid',
    editTypes: '/admin/edit-types',
    createLetter: '/createletter/:cid',
    reportImporterPublic: '/reportimporter',
    reportImporter: '/reportimporter/:cid?/:type?',
    leads: '/leads',
    leadAdd: '/leads/add',
    leadAddPublic: '/public/leads',
    viewLeads: '/leads/:id',
    referralAgents: '/referral-agents',
    addReferralAgent: '/referral-agents/add',
    viewReferralAgents: '/referral-agents/:id',
    referralOffices: '/referral-offices',
    addReferralOffice: '/referral-offices/add',
    viewReferralOffices: '/referral-offices/:id',
    franchiseAgents: '/franchise-agents',
    viewFranchiseAgents: '/franchise-agents/:id',
    addFranchiseAgent: '/franchise-agents/add',
    franchiseOffices: '/franchise-offices',
    viewFranchiseOffices: '/franchise-offices/:id',
    addFranchiseOffice: '/franchise-offices/add',
    emailTemplates: '/letter-templates',
    viewEmailTemplates: '/letter-templates/:id?',
    addEmailTemplate: '/letter-templates/add',
    portalIntegration: '/portal-integration',
    affiliateSignup: '/affiliate-signup',
    cbReportViewer: '/cb-report-viewer/:cid/:date',
    adminUserAccess: '/admin/user-access',
    htmlParser: '/htmlParser',
    htmlFreeScoreParser: '/htmlFreeScoreParser',
    passwordReset: '/reset-password/:token/:uid',
    masterData: '/master-data',
    myTasksAgents: '/franchise-agents/todo',
    myTasksReferralAgents: '/referral-agents/todo',
    loginHistory:  '/admin/login-history',
}
export const AccountOutcomes: IValueText[] = [
    {
        value: '',
        text: '- Select -'
    },
    {
        value: Outcome.REPAIRED,
        text: Outcome.REPAIRED
    },
    {
        value: Outcome.DELETED,
        text: Outcome.DELETED
    },
    {
        value: Outcome.IN_PROGRESS,
        text: Outcome.IN_PROGRESS
    },
    {
        value: Outcome.IN_DISPUTE,
        text: Outcome.IN_DISPUTE
    },
    {
        value: Outcome.DO_NOT_DISPUTE_BY_CONSUMER,
        text: Outcome.DO_NOT_DISPUTE_BY_CONSUMER
    },
    {
        value: Outcome.VerifiedByFCRA,
        text: Outcome.VerifiedByFCRA
    }
];
export const Messages: any = {
    APIAborted: 'API aborted as component unmounted',
    EmptyEmailChimp: 'Email Address not listed for this lead.  Enter an email address for the lead, and Save your changes first',
    InValidEmailChimp: '{0} is not a valid email address.  Enter a valid email address for the lead, and Save your changes',
    MailChimpNotSubscribed: 'Not yet subscribed to Mailchimp.  Choose from your lists below and click Save',
    GenericError: 'Some Error occured! Please try again later.'
};
export const Variables = {
    MIN_PWD_LENGTH: 8,
    EMPTY_GUID: '00000000-0000-0000-0000-000000000000',
    EMPTY_DATE1: '1/1/0001',
    EMPTY_DATE2: '1/1/1900',
    STANDARD_FASTRACK: 'Standard and Fast Track',
    BUREAU_LIST1: [
        { value: EnumBureausShorts.EQF, text: <BureauLogoComponent type={EnumBureausShorts.EQF} size={'sm'} /> },
        { value: EnumBureausShorts.EXP, text: <BureauLogoComponent type={EnumBureausShorts.EXP} size={'sm'} /> },
        { value: EnumBureausShorts.TU, text: <BureauLogoComponent type={EnumBureausShorts.TU} size={'sm'} /> },
        { value: EnumBureausShorts.CREDITOR, text: () => EnumBureausShorts.CREDITOR }
    ],
    // BUREAU_LIST2: [
    //     { value: EnumBureausShorts.CREDITOR, text: () => EnumBureausShorts.CREDITOR },
    //     { value: EnumBureausShorts.CC, text: () => EnumBureausShorts.CC, disabled: true },
    //     { value: EnumBureausShorts.BCC, text: () => EnumBureausShorts.BCC, disabled: true }
    // ],
    EmailTemplatesTypes: {
        WelcomeEmail: 'Welcome Email',
        AgentWelcomeEmail: 'Referral Agent Welcome Email',
        LeadWelcome: 'Lead Welcome Email',
        ReminderEmail: 'Reminder Email',
        PullEmail: 'Pull Email',
        NoticeAccUpdate: 'Account Update',
        FollowupEmail: 'Affiliate Agent Followup Email',
    },
    EmailTemplatesNames: {
        CustomerWelcome: 'Welcome Email',
        ReferralAgentWelcome: 'Referral Agent Welcome Email',
        LeadWelcome: 'Lead Welcome Email',
        Email15Days: '15 Day Email',
        Email35Days: '35 Day Email',
        NoticeAccUpdate: 'Notice of Account Update Email',
        NoticeAccUpdateDeletes: 'Notice of Account Update With Deletes Email',
        FollowupEmail: 'Affiliate Agent Followup Email'
    },
    ClientLoginMessage: `We appreciate the opportunity to serve you and we will work diligently to help you reach your credit goals.<br/>
                        Your first step each time you login is to check if you have any Client Tasks that need to be completed.<br/>    
                        The sooner you complete your tasks, the sooner we can complete your program.<br/><br/>    
                        See if you have any assigned To Do’s toward the bottom of your Client Info tab.<br/>    
                        We will continue to send reminders until your To Do’s are completed.<br/><br/><br/>       
                        
                        Thank you again for allowing us to serve you.`,
    EmailSourceTypes: {
        FRENCHISE_AGENT: 'FRENCHISE_AGENT',
        REFERRAL_AGENT: 'REFERRAL_AGENT',
        SENDER: 'SENDER'
    },
    AutoSelectAccountsForLetters: [
        { value: AutoSelectTypesForLetters.PersonalInfo, text: () => AutoSelectTypesForLetters.PersonalInfo },
        { value: AutoSelectTypesForLetters.Collections, text: () => AutoSelectTypesForLetters.Collections },
        { value: AutoSelectTypesForLetters.Inquiries, text: () => AutoSelectTypesForLetters.Inquiries },
        { value: AutoSelectTypesForLetters.LatePayments, text: () => AutoSelectTypesForLetters.LatePayments }
    ]
}

export const EmailSourceTypesList: ICheckboxList[] = [
    {
        text: 'Credit Agents',
        value: Variables.EmailSourceTypes.FRENCHISE_AGENT,
    },
    {
        text: 'Affiliate Agents',
        value: Variables.EmailSourceTypes.REFERRAL_AGENT,
    }
];
export const HTTP_ERROR_CODES = {
    BADREQUEST: 400,
    INTERNALERROR: 500,
    UNAUTHORISED: 403,
    NOTFOUND: 404,
}

export const PASSWORDSTRENGTH = [
    {
        id: 0,
        value: "Too weak",
        minDiversity: 0,
        minLength: 0
    },
    {
        id: 1,
        value: "Weak",
        minDiversity: 2,
        minLength: Variables.MIN_PWD_LENGTH
    },
    {
        id: 2,
        value: "Medium",
        minDiversity: 4,
        minLength: Variables.MIN_PWD_LENGTH + 2
    },
    {
        id: 3,
        value: "Strong",
        minDiversity: 4,
        minLength: Variables.MIN_PWD_LENGTH + 4  // 10
    }
]

export const PageErrors = {
    commonErrors: {
        firstName: 'First Name, ',
        lastName: 'Last Name, ',
        email: 'Email, ',
        emailOrUsername: 'Email/Username, ',
        passwordRequired: 'Password, ',
        validPassword: 'Valid Password, ',
        confirmPassword: 'Confirm Password, ',
        validConfirmPassword: 'Valid Confirm Password, ',
        usernameRequired: 'Username, ',
        passwordMatch: 'Passwords do not match!'
    },
    clientPageErrors: {
        processingStatus: 'Processing Status, ',
        franchiseId: 'Credit(Franchise) Agent, '
    },
    leadsPageErrors: {
        cellPhone: 'Cell Phone, '
    },
    companyOfficeErrors: {
        officeId: 'Office Name, ',
        role: 'Role, ',

    },
    affiliateOfficeErrors: {
        agentId: 'Credit Agent, ',
        officeName: 'Office Name, ',
        officeType: 'Office Type, ',

        agentsTab: {
            affiliateOffice: 'Affiliate Office, ',
        }
    }
}

export const DisputeReasons: IValueText[] = [
    {
        value: DisputeReason.PersonalInformationIsIncorrect,
        text: DisputeReason.PersonalInformationIsIncorrect
    },
    {
        value: DisputeReason.AccountIsNotMine,
        text: DisputeReason.AccountIsNotMine
    },
    {
        value: DisputeReason.StatusIsIncorrect,
        text: DisputeReason.StatusIsIncorrect
    },
    {
        value: DisputeReason.InformationOutdated,
        text: DisputeReason.InformationOutdated
    },
    {
        value: DisputeReason.InquiryIsTooOld,
        text: DisputeReason.InquiryIsTooOld
    },
    {
        value: DisputeReason.InquiryNotAuthorized,
        text: DisputeReason.InquiryNotAuthorized
    },
    {
        value: DisputeReason.AccountsWereClosed,
        text: DisputeReason.AccountsWereClosed
    },
    {
        value: DisputeReason.AccountIsBankruptcy,
        text: DisputeReason.AccountIsBankruptcy
    },
    {
        value: DisputeReason.MistakenIdentity,
        text: DisputeReason.MistakenIdentity
    },
    {
        value: DisputeReason.IdentityTheft,
        text: DisputeReason.IdentityTheft
    },
    {
        value: DisputeReason.DuplicateAccount,
        text: DisputeReason.DuplicateAccount
    },
    {
        value: DisputeReason.WrongAmountIsBeingReported,
        text: DisputeReason.WrongAmountIsBeingReported
    },
    {
        value: DisputeReason.WrongCreditor,
        text: DisputeReason.WrongCreditor
    },
    {
        value: DisputeReason.ValidateAccount,
        text: DisputeReason.ValidateAccount
    },
    {
        value: DisputeReason.AccountPaidOnTime,
        text: DisputeReason.AccountPaidOnTime
    },
    {
        value: DisputeReason.CreditorAgreedToRemove,
        text: DisputeReason.CreditorAgreedToRemove
    },
    {
        value: DisputeReason.TypeOfAccountIsIncorrect,
        text: DisputeReason.TypeOfAccountIsIncorrect
    },
    {
        value: DisputeReason.ReOpenAccount,
        text: DisputeReason.ReOpenAccount
    },
    {
        value: DisputeReason.DataInaccurateDeleteAccount,
        text: DisputeReason.DataInaccurateDeleteAccount
    }
];